import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import bgImages from "../assets/images/auth/auth-bg-pattern.png";
import Logo from "../assets/images/logo.png";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AuhMethods } from "../redux/services";
import { CurrentAuthMethod } from "../constants/AppConstants";
import PageLoader from "../component/PageLoader";
import ToastMessage from "../component/ToastMessage";
const useStyles = makeStyles((theme) => ({
  authThumb: {
    backgroundImage: `url(${bgImages})`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    height: "100vh",
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: 0,
      zIndex: 1,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(63, 81, 181, 0.5)",
    },
  },
  authCard: {
    position: "relative",
    zIndex: 3,
    maxWidth: "550px",
    width: "100%",
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)",
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "column",
    padding: "30px",
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.12)",
    },
  },

  titleRoot: {
    marginBottom: "18px !important",
    color: "rgba(0, 0, 0, 0.87)",
  },
  formcontrolLabelRoot: {
    "& .MuiFormControlLabel-label": {
      fontSize: 18,
    },
  },
}));
export default function Signin() {
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember_me, setRememberMe] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [errors, setErrors] = useState({ email: "", password: "" });
  const handleMessageClose = () => () => {
    setShowMessage(false);
  };
  const validateEmail = (email) => {
    if (!email) {
      return "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      return "Email address is invalid";
    }
    return "";
  };

  const validatePassword = (password) => {
    if (!password) {
      return "Password is required";
    } else if (password.length < 8) {
      return "Password must be at least 8 characters";
    }
    return "";
  };
  const onSubmit = () => {
    setLoading(true);
    const emailError = validateEmail(email);
    const passwordError = validatePassword(password);
    if (emailError || passwordError) {
      setErrors({ email: emailError, password: passwordError });
      setLoading(false);
    } else {
      setErrors({ email: "", password: "" });
      dispatch(AuhMethods[CurrentAuthMethod].onLogin({ email, password }));
      setTimeout(() => {
        setShowMessage(true);
        setMessage("Wrong credentials!");
        setLoading(false);
      }, 2000);
    }
  };
  return (
    <>
      {loading && <PageLoader />}
      {showMessage && (
        <ToastMessage
          open={showMessage}
          onClose={handleMessageClose()}
          message={message}
        />
      )}
      <Box className={classes.authThumb}>
        <Box className={classes.authCard}>
          <Box pb={4}>
            <Box
              component="img"
              src={Logo}
              sx={{ maxWidth: "100%", width: "150px" }}
            />
          </Box>
          <Typography
            component="div"
            variant="h1"
            className={classes.titleRoot}
          >
            Se connecter
          </Typography>
          <Box mb={1}>
            <TextField
              label="Email"
              fullWidth
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              onChange={(event) => setEmail(event.target.value)}
              defaultValue={email}
              error={!!errors.email}
              helperText={errors.email}
            />
          </Box>
          <Box mb={1}>
            <TextField
              label="Mot de passe"
              type="password"
              fullWidth
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              onChange={(event) => setPassword(event.target.value)}
              defaultValue={password}
              error={!!errors.password}
              helperText={errors.password}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <FormControlLabel
              className={classes.formcontrolLabelRoot}
              control={
                <Checkbox
                  name="remember_me"
                  checked={remember_me}
                  onChange={(event) => setRememberMe(event.target.checked)}
                />
              }
              label="Se souvenir de moi"
            />
            <Box component="p" fontSize={{ xs: 12, sm: 18 }}>
              <NavLink to="/forgot-password">Mot de passe oublié ?</NavLink>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Button variant="contained" onClick={onSubmit} color="primary">
              Se connecter
            </Button>

            <Box component="p" fontSize={{ xs: 12, sm: 18 }}>
              <NavLink to="/signup">Créer un compte</NavLink>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
