import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Link, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import bgImages from '../assets/images/auth/auth-bg-pattern.png'
import Logo from '../assets/images/logo.png' 
import { CurrentAuthMethod } from '../constants/AppConstants';

const useStyles = makeStyles(theme => ({
    authThumb: {
        backgroundImage: `url(${bgImages})`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100%',
        height: '100vh',
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 1,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(63, 81, 181, 0.5)',
        },
    },
    authCard: {
        position: 'relative',
        zIndex: 3,
        maxWidth: '550px',
        width: '100%',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
        borderRadius: 4,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        padding: '30px'
    },
    textFieldRoot: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.12)',
        },
    },
 
}))
export default function ForgotPassword() {
   /*  const { send_forget_password_email } = useSelector(({ auth }) => auth);
    const [open, setOpen] = React.useState(false);
    const [email, setEmail] = useState('demo@example.com');
    const dispatch = useDispatch();
    
    
  const onSubmit = () => {
    dispatch(AuhMethods[method].onForgotPassword({ email }));
  }; */
  const classes = useStyles();
    return (
        <>
            <Box className={classes.authThumb}>
                <Box className={classes.authCard}>
                    <Box pb={4}>
                        <Box component='img' src={Logo} sx={{ maxWidth: '100%', width: '150px' }} />
                    </Box>
                    <Typography component="div" variant="h1" className={classes.titleRoot}>
                        ForgotPassword
                    </Typography>
                    <Box mb={1}>
                        <TextField
                            label='Email'
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            className={classes.textFieldRoot}
                        />
                    </Box>

                    <Box display="flex" alignItems="center" justifyContent="space-between" my={3}>
                        <Button variant="contained" color="primary">
                            Reset Password
                        </Button>
                    </Box>
                    <Typography display="flex" alignItems="center" gap={0.5}>
                        Don't remember your email?
                        <Box className={'ml-2'}>
                            <Link href="#">
                                Contact Support
                            </Link>
                        </Box>
                    </Typography>
                </Box>
            </Box>
        </>
    )
}
