import { createTheme } from '@mui/material/styles';

export let theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#e91e63',
    }, 
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.3)',
      white: '#fff',
    },
    
  },
  typography: {
    fontFamily: 'Normal',
  },
  
});


theme = createTheme(theme, {
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: '20px',
          color: '#e91e63',
          fontWeight: 'bold',
          [theme.breakpoints.up('md')]: {
            fontSize: '23px',
            color: 'rgba(0, 0, 0, 0.87)',
          },
        },
        h2: {
          fontSize: 18,
          fontWeight: 'bold',
          [theme.breakpoints.up('md')]: {
            fontSize: 20,
          },
        },
        h3: {
          fontSize: 18,
          fontWeight: 'bold',
          [theme.breakpoints.up('md')]: {
            fontSize: 18,
          },
        },
        h4: {
          fontSize: 18,
          fontWeight: 'bold',
        },
        h5: {
          fontSize: 14,
          fontWeight: 400,
        },
        h6: {
          fontSize: 14,
          fontWeight: 'bold',
          letterSpacing: 0.5,
        },
        subtitle1: {
          fontSize: 18,
          fontWeight: 400,
          letterSpacing: 0.15,
        },
        subtitle2: {
          fontSize: 14,
          fontWeight: 'bold',
          letterSpacing: 0.1,
        },
        body1: {
          fontSize: 18,
          fontWeight: 400,
          letterSpacing: 0.5,
        },
        body2: {
          fontSize: 14,
          fontWeight: 400,
          letterSpacing: 0.25,
        },
      }
    },

    
  }
})

 


