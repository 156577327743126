import { combineReducers } from "redux";
import Common from "./Common";
import Auth from "./Auth";

const rootReducer = combineReducers({
  common: Common,
  auth: Auth,
});

export default rootReducer;
