import { fetchError, fetchStart, fetchSuccess } from '../../actions';
import { setAuthUser, setForgetPassMailSent, updateLoadUser } from '../../actions/Auth';
import React from 'react';
import axios from './config';

const JWTAuth = {
    onRegister: ({ email, password, firstName, lastName, phoneNumber, address, birthday, school, teacher, software,confirmPassword, postalCode, city, }) => {
      return dispatch => {
        dispatch(fetchStart());
        axios
          .post('users/register', {
            email: email,
            password: password,
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumber,
            address: address,
            birthday: (birthday ? birthday : new Date()).toISOString().substring(0, 10),
            school: school,
            teacher: teacher,
            software: software,
            confirmPassword:confirmPassword, postalCode:postalCode, city:city,
          })
          .then(({ data }) => {
            if (data.result) {
              localStorage.setItem('token', data.token.access_token);
              axios.defaults.headers.common['Authorization'] = data.token.access_token;
              dispatch(fetchSuccess());
              dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
            } else {
              dispatch(fetchError(data.error));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.message));
          });
      };
    },
  
    onLogin: ({ email, password }) => {
      return dispatch => {
        try {
          dispatch(fetchStart());
          axios
            .post('users/login', {
              email: email,
              password: password,
            })
            .then(({ data }) => {
              if (data.success) {
                console.log(data);
                localStorage.setItem('token', data.token);
                axios.defaults.headers.common['Authorization'] = data.token;
                const msg ={ message:'You are logged in Successfully',showToast:true}
                dispatch(fetchSuccess(msg));
                setTimeout(() => {
                  const msg ={message:'',showToast:false}
                  dispatch(fetchSuccess(msg));
                }, 3000);
                dispatch(JWTAuth.getAuthUser(true, data.token));
              } else {
                
                dispatch(fetchError(data.msg));
              }
            })
            .catch(function(error) {
              dispatch(fetchError(error.message));
            });
        } catch (error) {
          dispatch(fetchError(error.message));
        }
      };
    },
    onLogout: () => {
      return dispatch => {
        dispatch(fetchStart());
        axios
          .post('users/logout')
          .then(({ data }) => {
            if (data.success) {
              dispatch(fetchSuccess());
              localStorage.removeItem('token');
              delete axios.defaults.headers.common['Authorization'];
              dispatch(setAuthUser(null));
            } else {
              dispatch(fetchError(data.error));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.message));
          });
      };
    },
  
    getAuthUser: (loaded = false, token) => {
      return dispatch => {
        if (!token) {
          const token = localStorage.getItem('token');
          if (token) axios.defaults.headers.common['Authorization'] = token;
        }
        dispatch(fetchStart());
        dispatch(updateLoadUser(loaded));
        axios
          .post('users/me')
          .then(({ data }) => {
            if (data.success) {
              dispatch(fetchSuccess());
              dispatch(setAuthUser(data.user));
            } else {
              localStorage.removeItem('token');
              delete axios.defaults.headers.common['Authorization'];
              dispatch(updateLoadUser(true));
            }
          })
          .catch(function(error) {
            localStorage.removeItem('token');
            delete axios.defaults.headers.common['Authorization'];
            dispatch(updateLoadUser(true));
          });
      };
    },
  
    onForgotPassword: () => {
      return dispatch => {
        dispatch(fetchStart());
  
        setTimeout(() => {
          dispatch(setForgetPassMailSent(true));
          dispatch(fetchSuccess());
        }, 300);
      };
    },
    getSocialMediaIcons: () => {
      return <React.Fragment> </React.Fragment>;
    },
  };
  
  export default JWTAuth;