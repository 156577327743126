import React from "react";
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AuhMethods } from "../redux/services";
import { CurrentAuthMethod } from "../constants/AppConstants";
import { 
    Box, 
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from "@mui/material";

import { MdDashboard } from "react-icons/md";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { NavLink } from "react-router-dom";
import { Logout, PersonAdd, Settings } from "@mui/icons-material";

export default function Sidebar() {
    const { authUser } = useSelector(({ auth }) => auth);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const onLogoutClick = () => {
        setAnchorEl(null);
         dispatch(AuhMethods[CurrentAuthMethod].onLogout());

    };
    

    const MenuList = [
        {
            id: 1,
            Name: "Tableau de bord",
            link: "/",
            Icon: <MdDashboard />,
        },

    ];

    return (
        <>
            <Box sx={{ px: 3, my: 2 }}>
                <Box sx={{ px: 2 }}>
                    <Typography component="h3" sx={{mb: 0}}>{authUser?.first_name} {authUser?.last_name}</Typography>
                </Box>
                <Tooltip>
                    <IconButton
                    disableRipple
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2, display: 'flex', justifyContent: 'space-between', width: '100%' }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        elevation='false'
                    >

                        <Typography component="h3" variant="h6">
                        {authUser?.email}
                        </Typography>
                        <ArrowDropDownIcon />
                    </IconButton>
                </Tooltip>
                <Menu
       
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                
                    <MenuItem component={NavLink} to='/profile'  onClick={handleClose}>
                        <ListItemIcon>
                            <PersonAdd fontSize="small" />
                        </ListItemIcon>
                        Profil
                    </MenuItem>
                    <MenuItem component={NavLink} to='/signin' onClick={handleClose}>
                        <ListItemIcon>
                            <Settings fontSize="small" />
                        </ListItemIcon>
                        Paramètres
                    </MenuItem>
                    <MenuItem /* component={NavLink} to='/signin' */ onClick={onLogoutClick}  >
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        Se déconnecter
                    </MenuItem>
                </Menu>
            </Box >
            <List sx={{pr: 2}}>
                {MenuList.map((Item) => (
                    <ListItem
                        className="sidebarhover"
                        disableGutters
                        key={Item.Name}
                        disablePadding
                        
                    >
                        <ListItemButton
                            component={NavLink}

                            to={Item.link}
                            sx={{ color: "#4D4A4A", pl: 5 }}
                        >
                            <ListItemIcon
                                sx={{ color: "inherit", fontSize: "22px", minWidth: "36px" }}
                            >
                                {Item.Icon}
                            </ListItemIcon>
                            <ListItemText
                                primary={Item.Name}
                                sx={{
                                    "& .MuiListItemText-primary": {
                                        fontWeight: "300",
                                    },
                                    "&:hover": {
                                        "& .MuiListItemText-primary": {
                                            color: "#2F54EB", // Add your desired hover color here
                                        },
                                    },
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}

            </List>
            {/* <Typography
        align="center"
        variant="subtitle1"
        sx={{ mt: 3, display: { xs: "none", sm: "block" } }}
      >
        {" "}
        © KI. Copyright 2023
      </Typography> */}
        </>
    );
}
