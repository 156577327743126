import React from "react";
import { Link, Outlet } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Box,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Toolbar,
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { IoIosCloseCircleOutline } from "react-icons/io";
import Sidebar from "../component/Sidebar";
import Logo from "../assets/images/logo-white.png";

const drawerWidth = 300; // Reduced width for the left sidebar

export default function Layout({ children }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      {/* Left Sidebar */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
      >
        <Box sx={{ overflow: "auto" }}>
          <IconButton
            aria-label="close"
            onClick={handleDrawerToggle}
            sx={{
              position: "absolute",
              right: 4,
              top: 26,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <IoIosCloseCircleOutline />
          </IconButton>

          <a href="/">
            <Box
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                marginBottom: "5px",
                paddingBottom: "10px",
              }}
            >
              {/* <Avatar>H</Avatar>
              mansih */}
            </Box>
          </a>
          <Divider />
          <Sidebar />
        </Box>
      </Drawer>

      {/* Main Content */}
      <Box
        component="div"
        sx={{ flexGrow: 1, p: 3 }}
      >
        <AppBar position="fixed">
          <Toolbar>
            {/* Button to open the left sidebar */}
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }} // Margin to separate from other components
            >
              <MenuIcon />
            </IconButton>
            {/* Logo */}
            <Box component="img" src={Logo} sx={{ maxWidth: "150px" }} />

            {/* Navigation Links */}
            <Box sx={{ display: "flex", ml: "auto" }}>
              <Grid sx={{ marginLeft: 1 }}>
                <Link to={"/contact-me"} style={{ color: "white", textDecoration: "none" }}>Contactez moi</Link>{" "}
              </Grid>
              <Grid sx={{ marginLeft: 3 }}>
                <Link to={'/report-bug'} style={{ color: "white", textDecoration: "none" }}>Signaler un bug</Link> 
              </Grid>
            </Box>
          </Toolbar>
        </AppBar>

        {/* Main Content Area */}
        <Container sx={{ mt: 8 }} maxWidth={false}>
          {children}
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
}
