import React from 'react';
import { Box, Typography, Grid, Paper, Avatar, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Layout from '../layout/Layout';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    margin: 'auto',
  },
}));

const ProfilePage = () => {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);

  return (
    <Layout>
<Box className={classes.root}>
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar} alt="Avatar de l'utilisateur" src="/path/to/avatar.jpg" />
        <Typography variant="h5" gutterBottom>
          {authUser?.first_name || 'Nom non disponible'} {authUser?.last_name || 'Nom de famille non disponible'}
        </Typography>
      </Paper>
    </Grid>
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Typography variant="subtitle1" gutterBottom>
          Téléphone: {authUser?.phone_number || 'Numéro de téléphone non disponible'}
        </Typography>
      </Paper>
    </Grid>
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Typography variant="body1" gutterBottom>
          Adresse: {authUser?.address || 'Adresse non disponible'}
        </Typography>
      </Paper>
    </Grid>
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Typography variant="h6" gutterBottom>
          Date de naissance: {authUser.birthday || 'Date de naissance non disponible'}
        </Typography>
      </Paper>
    </Grid>
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Typography variant="body1" gutterBottom>
          École: {authUser?.school || 'École non disponible'}
        </Typography>
      </Paper>
    </Grid>
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Typography variant="body1" gutterBottom>
          Enseignant: {authUser.teacher ? 'Oui' : 'Non'}
        </Typography>
      </Paper>
    </Grid>
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Typography variant="body1" gutterBottom>
          Détails du logiciel: {authUser?.software || 'Détails du logiciel non disponibles'}
        </Typography>
      </Paper>
    </Grid>
  </Grid>
</Box>




    </Layout>
  );
};

export default ProfilePage;
