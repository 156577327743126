import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  initialURL: "/",
  error: "",
  message: "",
  loading: false,
  showToastMessage: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {
        ...state,
        error: "",
        message: "",
        loading: true,
        showToastMessage: false,
      };
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        error: "",
        loading: false,
        message: action.payload.message,
        showToastMessage: action.payload.showToast,
      };
    }
    case FETCH_ERROR: {
      return { ...state, loading: false, message: "", error: action.payload };
    }
    default:
      return state;
  }
};
