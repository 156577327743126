import './App.css';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { AuhMethods } from './redux/services';
import { CurrentAuthMethod } from './constants/AppConstants';
import Router from './routers/index';
const useStyles = makeStyles(() => ({
  circularProgressRoot: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
function App() {
  const [showLayoutLoader, setLayoutLoader] = useState(true);
  // const { layout } = useContext(AppContext);
  const { loadUser } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();    
  
  useEffect(() => {
      dispatch(AuhMethods[CurrentAuthMethod].getAuthUser());
      setLayoutLoader(false);
  }, [dispatch]);

  if (showLayoutLoader || !loadUser) {
    return (
      <div className={classes.circularProgressRoot}>
        <CircularProgress />
      </div>
    );
  }else{
    
  }
  return ( 
    <Router /> 
  );
  
}

export default App;
