import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AuhMethods } from "../redux/services";
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
  RadioGroup,
  Radio
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import bgImages from "../assets/images/auth/auth-bg-pattern.png";
import Logo from "../assets/images/logo.png";
import { NavLink } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import { CurrentAuthMethod } from "../constants/AppConstants";
import dayjs from "dayjs";
import PageLoader from "../component/PageLoader";
import ToastMessage from "../component/ToastMessage";
import { useNavigate } from "react-router-dom";
import "../constants/FormLabels";
import {
  Address,
  City,
  Confirm_Password,
  Create_an_Account,
  Date_of_Birth,
  Email,
  First_Name,
  Have_an_Account,
  How_Do_You_Find_About_Software_Register,
  Iam_already_a_Member,
  Last_Name,
  Name_Conservatory_Schoolr,
  No,
  Password,
  Phone,
  Postal_Code,
  Teacher,
  Yes,
} from "../constants/FormLabels";
// import { Email } from "../constants/FormLabels";
const useStyles = makeStyles((theme) => ({
  authThumb: {
    backgroundImage: `url(${bgImages})`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: "40px 0",
    width: "100%",
    minHeight: "100vh",
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: 0,
      zIndex: 1,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(63, 81, 181, 0.5)",
    },
  },
  authCard: {
    position: "relative",
    zIndex: 3,
    maxWidth: "550px",
    width: "100%",
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)",
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "column",
    padding: "30px",
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.12)",
    },
  },
  textAcc: {
    textAlign: "center",
    "& a": {
      marginLeft: 4,
    },
  },

  titleRoot: {
    marginBottom: "18px !important",
    color: "rgba(0, 0, 0, 0.87)",
  },
  formcontrolLabelRoot: {
    "& .MuiFormControlLabel-label": {
      fontSize: 18,
    },
  },
}));
export default function Signup() {
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [birthday, setBirthday] = useState(dayjs(new Date()));
  const [school, setSchool] = useState("");
  const [teacher, setTeacher] = useState(false);
  const [software, setSoftware] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    address: "",
    birthday: "",
    school: "",
    teacher: "",
    software: "",
    email: "",
    city: "",
    postalCode: "",
    confirmPassword: "",
    password: "",
  });
  const classes = useStyles();

  const handleMessageClose = () => () => {
    setShowMessage(false);
  };
  const validateEmail = (email) => {
    if (!email) {
      return "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      return "Email address is invalid";
    }
    return "";
  };

  const validateName = (name, textName) => {
    if (!name) {
      return textName + " is required";
    }
    return "";
  };

  const validatePassword = (password) => {
    if (!password) {
      return "Password is required";
    } else if (password.length < 8) {
      return "Password must be at least 8 characters";
    }
    return "";
  };
  const validateConfirmPassword = (confirmPassword) => {
    if (!confirmPassword) {
      return "Confirm Password is required";
    } else if (confirmPassword !== password) {
      return "Passwords do not match";
    }
    return "";
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneNumber) {
      return "Phone Number is required";
    } else if (!phoneRegex.test(phoneNumber)) {
      return "Invalid phone number";
    }
    return "";
  };
  const validatePostalCode = (postalCode) => {
    const postalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
    if (!postalCode) {
      return "Postal Code is required";
    }
    return "";
  };

  const validateCity = (city) => {
    if (!city) {
      return "City is required";
    }
    return "";
  };
  const onSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    // Validate each input field
    const emailError = validateEmail(email);
    const passwordError = validatePassword(password);
    const confirmPasswordError = validateConfirmPassword(confirmPassword);
    const firstNameError = validateName(firstName, First_Name);
    const lastNameError = validateName(lastName, Last_Name);
    const phoneNumberError = validatePhoneNumber(phoneNumber);
    const addressError = validateName(address, Address);
    const postalCodeError = validatePostalCode(postalCode);
    const cityError = validateCity(city);

    // Update the errors state with validation results
    setErrors({
      email: emailError,
      password: passwordError,
      confirmPassword: confirmPasswordError,
      firstName: firstNameError,
      lastName: lastNameError,
      phoneNumber: phoneNumberError,
      address: addressError,
      postalCode: postalCodeError,
      city: cityError,
      birthday: "", // Assuming birthday validation is handled separately, update as needed
      school: "", // Assuming school validation is handled separately, update as needed
      teacher: "", // Assuming teacher validation is handled separately, update as needed
      software: "", // Assuming software validation is handled separately, update as needed
    });

    // If any validation error exists, do not proceed with form submission
    if (
      emailError ||
      passwordError ||
      confirmPasswordError ||
      firstNameError ||
      lastNameError ||
      phoneNumberError ||
      addressError ||
      postalCodeError ||
      cityError
    ) {
      return;
    }
    setLoading(true);

    try {
      await dispatch(
        AuhMethods[CurrentAuthMethod].onRegister({
          email,
          password,
          firstName,
          lastName,
          phoneNumber,
          address,
          birthday,
          school,
          teacher,
          software,
          confirmPassword,
          postalCode,
          city,
        })
      );
      setMessage("Registration successful");
      setShowMessage(true);
      setTimeout(() => {
        navigate("/signin"); // Redirect to signin page after 3 seconds
      }, 3000);
    } catch (error) {
      setMessage(error.message || "Registration failed");
      setShowMessage(true);
    }

    setLoading(false);
  };
  return (
    <>
      {loading && <PageLoader />}
      {showMessage && (
        <ToastMessage
          open={showMessage}
          onClose={handleMessageClose()}
          message={message}
        />
      )}
      <Box className={classes.authThumb}>
        <Box className={classes.authCard}>
          <Box pb={4}>
            <Box
              component="img"
              src={Logo}
              sx={{ maxWidth: "100%", width: "150px" }}
            />
          </Box>
          <Typography
            component="div"
            variant="h1"
            className={classes.titleRoot}
          >
            {Create_an_Account}
          </Typography>
          <Box mb={1}>
            <TextField
              label={Email}
              onChange={(event) => setEmail(event.target.value)}
              defaultValue={email}
              fullWidth
              required
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              error={!!errors.email}
              helperText={errors.email}
            />
          </Box>
          <Box mb={1}>
            <TextField
              label={Password}
              type="password"
              onChange={(event) => setPassword(event.target.value)}
              defaultValue={password}
              fullWidth
              required
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              error={!!errors.password}
              helperText={errors.password}
            />
          </Box>
          <Box mb={1}>
            <TextField
              label={Confirm_Password}
              type="password"
              onChange={(event) => setConfirmPassword(event.target.value)}
              defaultValue={confirmPassword}
              fullWidth
              required
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
            />
          </Box>

          <Box mb={1}>
            <TextField
              label={First_Name}
              onChange={(event) => setFirstName(event.target.value)}
              defaultValue={firstName}
              fullWidth
              required
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              error={!!errors.firstName}
              helperText={errors.firstName}
            />
          </Box>
          <Box mb={1}>
            <TextField
              label={Last_Name}
              onChange={(event) => setLastName(event.target.value)}
              defaultValue={lastName}
              fullWidth
              required
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              error={!!errors.lastName}
              helperText={errors.lastName}
            />
          </Box>
          <Box mb={1}>
            <TextField
              label={Phone}
              onChange={(event) => setPhoneNumber(event.target.value)}
              defaultValue={phoneNumber}
              fullWidth
              required
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
            />
          </Box>
          {/* <Box mb={1}>
            <TextField
              label={Address}
              onChange={(event) => setAddress(event.target.value)}
              defaultValue={address}
              fullWidth
              required
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              error={!!errors.address}
              helperText={errors.address}
            />
          </Box> */}
          <Box mb={1}>
            <TextField
              label={Postal_Code}
              onChange={(event) => setPostalCode(event.target.value)}
              defaultValue={postalCode}
              fullWidth
              required
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              error={!!errors.postalCode}
              helperText={errors.postalCode}
            />
          </Box>

          <Box mb={1.5}>
            <TextField
              label={City}
              onChange={(event) => setCity(event.target.value)}
              defaultValue={city}
              fullWidth
              required
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              error={!!errors.city}
              helperText={errors.city}
            />
          </Box>
          <Box mb={2}>
            <DatePicker
              margin="normal"
              variant="standard"
              value={birthday}
              onChange={setBirthday}
              label={Date_of_Birth}
              required
              error={!!errors.birthday}
              helperText={errors.birthday}
            />
          </Box>
          <Box mb={1}>
            <TextField
              label={Name_Conservatory_Schoolr}
              onChange={(event) => setSchool(event.target.value)}
              defaultValue={school}
              fullWidth
              requiredschool
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              /* error={!!errors.school}
              helperText={errors.school} */
            />
          </Box>

          <Box>
  <FormControlLabel
    label={Teacher}
    labelPlacement="start"
    control={
      <RadioGroup
        aria-label="teacher"
        name="teacher"
        value={teacher.toString()}
        onChange={(event) => setTeacher(event.target.value === "true")}
        row
      >
        <FormControlLabel
          value="true"
          control={<Radio />}
          label={Yes}
          labelPlacement="start"
        />
        <FormControlLabel
          value="false"
          control={<Radio />}
          label={No}
          labelPlacement="start"
        />
      </RadioGroup>
    }
  />
</Box>


          <Box mb={2}>
            <TextField
              label={How_Do_You_Find_About_Software_Register}
              onChange={(event) => setSoftware(event.target.value)}
              defaultValue={software}
              fullWidth
              required
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              /* error={!!errors.school}
              helperText={errors.school} */
            />
          </Box>
          <Box mb={1}>
            <TextField
              label={Address}
              onChange={(event) => setAddress(event.target.value)}
              defaultValue={address}
              fullWidth
              required
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              error={!!errors.address}
              helperText={errors.address}
            />
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Button variant="contained" onClick={onSubmit} color="primary">
              s’enregistrer
            </Button>

            <Box component="p" fontSize={{ xs: 12, sm: 18 }}>
              <NavLink to="/signin">{Iam_already_a_Member}</NavLink>
            </Box>
          </Box>
          <Typography className={classes.textAcc}>
            <NavLink to="/signin">Sign In</NavLink>
          </Typography>
        </Box>
      </Box>
    </>
  );
}
