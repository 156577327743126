export const First_Name ='Prénom';
export const Last_Name='Nom de famille'
export const Email ='E-mail';
export const Password ='Mot de passe';
export const Confirm_Password ='Confirmez le mot de passe';
export const Phone ='Téléphone';
export const Address ='Instrument';
export const Postal_Code='Code Postal';
export const City='Ville';
export const Date_of_Birth='Date de naissance';
export const Name_Conservatory_Schoolr='Nom conservatoire';
export const Teacher='Professeur'
export const Yes= 'OUI';
export const No='NON';
export const How_Do_You_Find_About_Software_Register='Comment nous avez-vous connu ?';
export const Iam_already_a_Member ='Je suis déjà membre';
export const Log_In_here='Connectez-vous ici'
export const Create_an_Account='créer un compte'
export const Have_an_Account ='Avoir un compte'
