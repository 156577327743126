import axios from 'axios';
import { ApiURL } from '../../../config';

export default axios.create({
  baseURL: ApiURL, //YOUR_API_URL HERE
//   baseURL: baseURL, //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  },
});
