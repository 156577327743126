import React, { useState } from 'react';
import { Button, TextField, Box, Typography, Snackbar, Alert } from '@mui/material';
import BugReportIcon from '@mui/icons-material/BugReport';
import Layout from '../layout/Layout';

function ReportBug() {
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        description: '',
        steps: '',
        severity: ''
    });
    const [errors, setErrors] = useState({});

    const validate = () => {
        let temp = {};
        temp.email = (/^$|.+@.+..+/).test(formData.email) ? "" : "Email non valide.";
        temp.name = formData.name ? "" : "Ce champ est requis.";
        temp.description = formData.description ? "" : "Ce champ est requis.";
        temp.steps = formData.steps ? "" : "Ce champ est requis.";
        temp.severity = formData.severity ? "" : "Ce champ est requis.";
        setErrors({
            ...temp
        });
        return Object.values(temp).every(x => x === "");
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            console.log('Form Data:', formData);  // For demo purposes
            setOpen(true);
            setFormData({
                name: '',
                email: '',
                description: '',
                steps: '',
                severity: ''
            }); // Clear form
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <Layout>
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Typography component="h1" variant="h5">
                Signaler un bug
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Votre Nom"
                    name="name"
                    autoComplete="name"
                    autoFocus
                    value={formData.name}
                    onChange={handleChange}
                    error={!!errors.name}
                    helperText={errors.name}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Votre Email"
                    name="email"
                    autoComplete="email"
                    value={formData.email}
                    onChange={handleChange}
                    error={!!errors.email}
                    helperText={errors.email}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="description"
                    label="Description du Bug"
                    type="text"
                    id="description"
                    autoComplete="bug-description"
                    multiline
                    rows={4}
                    value={formData.description}
                    onChange={handleChange}
                    error={!!errors.description}
                    helperText={errors.description}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="steps"
                    label="Étapes pour Reproduire"
                    type="text"
                    id="steps"
                    autoComplete="bug-steps"
                    multiline
                    rows={4}
                    value={formData.steps}
                    onChange={handleChange}
                    error={!!errors.steps}
                    helperText={errors.steps}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="severity"
                    label="Sévérité (1-5)"
                    type="number"
                    id="severity"
                    autoComplete="bug-severity"
                    value={formData.severity}
                    onChange={handleChange}
                    error={!!errors.severity}
                    helperText={errors.severity}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    endIcon={<BugReportIcon />}
                >
                    Signaler le Bug
                </Button>
            </Box>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Bug signalé avec succès !
                </Alert>
            </Snackbar>
        </Box>
        </Layout>
    );
}

export default ReportBug;
