/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  TextField,
  Item,
  Snackbar,
} from "@mui/material";
import ToastMessage from "../component/ToastMessage";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import Layout from "../layout/Layout";
import SendIcon from "@mui/icons-material/Send";
import { styled, makeStyles, useTheme } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";

import {
  CloudUpload,
  PhotoCamera,
  ZoomIn,
  ZoomOut,
  ZoomOutMap,
  CenterFocusStrong,
} from "@mui/icons-material";
import { fetchError, fetchStart, fetchSuccess } from "../redux/actions";
import CmtImage from "../@coremat/CmtImage";
import clsx from "clsx";
import styles from "./styles.module.css";
import axios from "axios";
import { ApiURL } from "../config";
import PageLoader from "../component/PageLoader";

export default function Dashboard(props) {
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authUser, loadUser } = useSelector(({ auth }) => auth);
  // const { error, loading, message } = useSelector(({ common }) => common);
  const [loading, setLoading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [endX, setEndX] = useState(0);
  const [endY, setEndY] = useState(0);
  const canvasRef = useRef(null);

  const videoRef = useRef(null);
  const canvasScanRef = useRef(null);
  const [open_scan, setOpenScan] = useState(false);
  const [currentMidi, setCurrentMidi] = useState(0);
  const [gotoDetail, setGotoDetail] = useState(false);

  const [scale, setScale] = useState(1);
  const [zoomFocus, setZoomFocus] = useState(false);
  let isImageLoading = false;

  const handleMessageClose = () => () => {
    setShowMessage(false);
  };
  const [photo_img, setPhotoImg] = useState(undefined);
  const [photo_img_url, setPhotoImgUrl] = useState(
    "https://via.placeholder.com/600x400"
  );
  const [selectedImageURL, setSelectedImageURL] = useState([]);
  useEffect(() => {
    const imageContainer = document.querySelector("#image-container");
    const myCanvas = document.querySelector("#myCanvas");
    const enlargedImage = document.querySelector("#enlarged-image");
    enlargedImage.style.backgroundImage = `url(${photo_img_url})`;
    enlargedImage.style.transform = "scale(2)";

    myCanvas.addEventListener("mousemove", function (event) {
      const mouseX = event.clientX;
      const mouseY = event.clientY;
      const rect = imageContainer.getBoundingClientRect();
      const canvas_rect = myCanvas.getBoundingClientRect();

      // Set the position of the enlarged image container
      enlargedImage.style.top = `${
        mouseY - rect.top - 100 + imageContainer.scrollTop
      }px`;
      enlargedImage.style.left = `${
        mouseX - rect.left - 100 + imageContainer.scrollLeft
      }px`;
      enlargedImage.style.display = "block";
      // if (photo_img_url != 'https://via.placeholder.com/600x400')
      //   enlargedImage.style.backgroundImage = `url(${myCanvas.toDataURL('image/jpeg')})`;
      // Set the content of the enlarged image container
      enlargedImage.style.backgroundPositionX = `${-(
        mouseX -
        canvas_rect.left -
        100
      )}px`;
      enlargedImage.style.backgroundPositionY = `${-(
        mouseY -
        canvas_rect.top -
        100
      )}px`;
    });

    // Register the mouseout event listener
    imageContainer.addEventListener("mouseout", function () {
      // Hide the enlarged image container when the mouse moves out
      enlargedImage.style.display = "none";
    });
    if (photo_img) handleFileInputChange(photo_img);
  }, []);

  useEffect(() => {
    const enlargedImage = document.querySelector("#enlarged-image");
    enlargedImage.style.backgroundImage = `url(${photo_img_url})`;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const img = new Image();
    img.onload = function () {
      let width = img.width;
      let height = img.height;

      // set the canvas dimensions to match the scaled image size
      canvas.width = width;
      canvas.height = height;

      // draw the image onto the canvas at the correct size
      ctx.drawImage(img, 0, 0, width, height);
    };
    img.src = photo_img_url;
  }, [photo_img_url]);

  const handleSubmitClick = () => {
    if (selectedImageURL.length === 0) {
      setMessage("No images selected.");
      setShowMessage(true);
      return;
    }

    setLoading(true);
    const eventData = new FormData();

    selectedImageURL.forEach((item) => {
      eventData.append("images", item.file);
      eventData.append("sources", item.source);
      eventData.append("solutions", item.solution);
    });

    axios
      .post(`${ApiURL}recognize_image/`, eventData)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          dispatch(fetchSuccess("Success!"));
          navigate("/detail-page", { state: { selectedImageURL } });
        } else {
          dispatch(fetchError(response.data.error));
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        dispatch(fetchError(error.message));
      });
  };

  console.log(selectedImageURL);
  console.log();
  const handleGotoDetailClick = () => {
    navigate("/detail-page", { state: { selectedImageURL, photo_img } });
  };

  const handleFileInputChange = (fileObj) => {
    setLoading(true);
    if (fileObj && fileObj.type === "application/pdf") {
      // The file is a PDF
      setPhotoImg(fileObj);
      loadPdfFile(fileObj, 1);
      setOpenSnackbar(true); // Show Snackbar when an image is selected
    } else if (fileObj && fileObj.type.startsWith("image/")) {
      // The file is an image
      setPhotoImg(fileObj);
      setNumPages(0);
      setCurrentPage(1);
      loadImageFile(fileObj);
    } else {
      setLoading(false);
      // The file is not a PDF or an image
      setShowMessage(true);
      setMessage("Invalid file format");
      dispatch(fetchError("Invalid file format"));
    }
    fileObj = null;
  };
  const loadImageFile = (file) => {
    let img_file_name = file.name;
    file = new File([file], img_file_name, file);
    setPhotoImgUrl(URL.createObjectURL(file));
    setLoading(false);
  };

  // const [numPages, setNumPages] = useState(0);
  // const [currentPage, setCurrentPage] = useState(1);
  const [photoImg] = useState(null);
  const loadPdfFile = async (file, pageNumber = 1) => {
    try {
      const pdfjsLib = await import("pdfjs-dist/webpack");
      const pdf = await pdfjsLib.getDocument(URL.createObjectURL(file)).promise;
      setNumPages(pdf.numPages);
      await renderPdfPage(pdf, pageNumber);
      setLoading(false);
    } catch (error) {
      console.error("Error loading PDF:", error);
      setLoading(false);
    }
  };

  const renderPdfPage = async (pdf, pageNumber) => {
    try {
      const page = await pdf.getPage(pageNumber);
      const scale = 1.5; // Adjust the scale as needed
      const viewport = page.getViewport({ scale });

      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      await page.render(renderContext).promise;
      setPhotoImgUrl(canvas.toDataURL("image/jpeg")); // Update image URL
    } catch (error) {
      console.error("Error rendering PDF page:", error);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      setCurrentPage(newPage);
      loadPdfFile(photo_img, newPage); // corrected variable name
    }
  };
  const handleNextPage = () => {
    if (currentPage < numPages) {
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      loadPdfFile(photo_img, newPage);
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  const handleZoomFocusClick = () => {
    setZoomFocus(!zoomFocus);
  };
  const handleZoomInClick = () => {
    const new_scale = scale * 1.1;
    if (new_scale > 2) return;
    try {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      const image = new Image();

      image.onload = () => {
        try {
          if (isImageLoading) {
            isImageLoading = false;
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.drawImage(image, 0, 0, canvas.width, canvas.height);
            const enlargedImage = document.querySelector("#enlarged-image");
            enlargedImage.style.backgroundImage = `url(${canvas.toDataURL(
              "image/jpeg"
            )})`;
            setScale(new_scale);
          }
        } catch (error) {
          console.log(error);
        }
      };

      if (!isImageLoading) {
        isImageLoading = true;

        canvas.width *= 1.1;
        canvas.height *= 1.1;

        image.src = photo_img_url;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleZoomOutClick = () => {
    const new_scale = scale / 1.1;
    if (new_scale < 0.3) return;
    try {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      const image = new Image();

      image.onload = () => {
        try {
          if (isImageLoading) {
            isImageLoading = false;
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.drawImage(image, 0, 0, canvas.width, canvas.height);
            const enlargedImage = document.querySelector("#enlarged-image");
            enlargedImage.style.backgroundImage = `url(${canvas.toDataURL(
              "image/jpeg"
            )})`;
            setScale(new_scale);
          }
        } catch (error) {
          console.log(error);
        }
      };

      if (!isImageLoading) {
        isImageLoading = true;

        canvas.width /= 1.1;
        canvas.height /= 1.1;

        image.src = photo_img_url;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleZoomOutMapClick = () => {
    try {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      const image = new Image();
      let initialScale = scale;

      image.onload = () => {
        try {
          if (isImageLoading) {
            isImageLoading = false;
            const imageContainer = document.querySelector("#image-container");
            const fullWidth = imageContainer.clientWidth;
            const new_scale = (initialScale * fullWidth) / canvas.width;
            canvas.width = fullWidth;
            canvas.height = (fullWidth * image.height) / image.width;

            context.clearRect(0, 0, canvas.width, canvas.height);
            context.drawImage(image, 0, 0, canvas.width, canvas.height);
            const enlargedImage = document.querySelector("#enlarged-image");
            enlargedImage.style.backgroundImage = `url(${canvas.toDataURL(
              "image/jpeg"
            )})`;
            setScale(new_scale);
          }
        } catch (error) {
          console.log(error);
        }
      };

      if (!isImageLoading) {
        isImageLoading = true;
        image.src = photo_img_url;
      }
    } catch (error) {
      console.log(error);
    }
  };
  // const handleMouseDown = (e) => {
  //   const canvas = canvasRef.current;
  //   const rect = canvas.getBoundingClientRect();
  //   const x = e.clientX - rect.left;
  //   const y = e.clientY - rect.top;

  //   setIsDragging(true);
  //   setStartX(x);
  //   setStartY(y);
  //   setEndX(x);
  //   setEndY(y);
  // };

  // const handleMouseMove = (e) => {
  //   const enlargedImage = document.querySelector("#enlarged-image");

  //   if (!zoomFocus) {
  //     enlargedImage.style.display = "none";
  //   } else {
  //     enlargedImage.style.display = "block";
  //   }
  //   if (!isDragging) return;

  //   const canvas = canvasRef.current;
  //   const rect = canvas.getBoundingClientRect();
  //   const x = (e.clientX - rect.left) / scale; // Adjust for scale
  //   const y = (e.clientY - rect.top) / scale; // Adjust for scale

  //   setEndX(x);
  //   setEndY(y);
  //   redrawCanvas();
  //   enlargedImage.style.display = "none";
  // };

  // const handleMouseUp = () => {
  //   const enlargedImage = document.querySelector("#enlarged-image");

  //   if (!zoomFocus) {
  //     enlargedImage.style.display = "none";
  //   } else {
  //     enlargedImage.style.display = "block";
  //   }

  //   setIsDragging(false);
  //   redrawCanvas();
  //   extractSelectedArea();
  //   try {
  //     const canvas = canvasRef.current;
  //     enlargedImage.style.backgroundImage = `url(${canvas.toDataURL(
  //       "image/jpeg"
  //     )})`;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const redrawCanvas = () => {
  //   const canvas = canvasRef.current;
  //   const ctx = canvas.getContext("2d");

  //   // Clear the canvas
  //   ctx.clearRect(0, 0, canvas.width, canvas.height);

  //   // Draw the original image
  //   const img = new Image();
  //   img.src = photo_img_url;
  //   ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

  //   // Draw the selected rectangular area

  //   const width = endX - startX;
  //   const height = endY - startY;
  //   ctx.strokeStyle = "rgba(0, 0, 255, 0.5)";
  //   ctx.lineWidth = 2;

  //   ctx.strokeRect(startX, startY, width, height);
  // };
  const handleMouseDown = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const scale = window.devicePixelRatio; // Adjust for device pixel ratio if necessary
    const x = (e.clientX - rect.left) * (canvas.width / rect.width);
    const y = (e.clientY - rect.top) * (canvas.height / rect.height);
  
    setIsDragging(true);
    setStartX(x);
    setStartY(y);
    setEndX(x);
    setEndY(y);
  };
  
  const handleMouseMove = (e) => {
    if (!isDragging) return;
  
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const scale = window.devicePixelRatio; // Adjust for device pixel ratio if necessary
    const x = (e.clientX - rect.left) * (canvas.width / rect.width);
    const y = (e.clientY - rect.top) * (canvas.height / rect.height);
  
    setEndX(x);
    setEndY(y);
    redrawCanvas();
  };
  
  const handleMouseUp = () => {
    setIsDragging(false);
    redrawCanvas();
    extractSelectedArea();
  };
  
  const redrawCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
  
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas first

    const img = new Image();
    img.src = photo_img_url;
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

    // Draw the selected rectangular area

    const width = endX - startX;
    const height = endY - startY;
    ctx.strokeStyle = "rgba(0, 0, 255, 0.5)";
    ctx.lineWidth = 2;

    ctx.strokeRect(startX, startY, width, height);
  };
  
  const extractSelectedArea = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
  
    // Ensure this is called after the image has been drawn
    const img = new Image();
    img.onload = () => {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  
      const tempCanvas = document.createElement("canvas");
      const tempCtx = tempCanvas.getContext("2d");
      const width = Math.abs(endX - startX);
      const height = Math.abs(endY - startY);
  
      // Set dimensions of the temporary canvas to the selected area
      tempCanvas.width = width;
      tempCanvas.height = height;
  
      // Draw the selected area onto the temporary canvas
      tempCtx.drawImage(
        canvas,
        startX,
        startY,
        width,
        height,
        0,
        0,
        width,
        height
      );
  
      const dataURL = tempCanvas.toDataURL("image/png");
      const blob = dataURLtoBlob(dataURL);
  
      // Create a new File object from the Blob
      const file = new File([blob], "selected-area.png", { type: "image/png" });
  
      // Update the selected images array with the new file
      const new_id = selectedImageURL.length > 0
        ? Math.max(...selectedImageURL.map(item => item.id)) + 1
        : 1;
  
      setSelectedImageURL([...selectedImageURL, {
        id: new_id,
        image: dataURL,
        file: file,
        source: "",
        solution: ""
      }]);
    };
    img.src = photo_img_url; // Make sure the image is reloaded for extraction
  };
  
  const handleSourceChange = (id, value) => {
    const newImages = selectedImageURL.map((item) => {
      if (item.id === id) {
        return { ...item, source: value };
      }
      return item;
    });
    setSelectedImageURL(newImages);
  };

  const handleSolutionChange = (id, value) => {
    const newImages = selectedImageURL.map((item) => {
      if (item.id === id) {
        return { ...item, solution: value };
      }
      return item;
    });
    setSelectedImageURL(newImages);
  };

  // console.log("selectedImageURL",selectedImageURL)

  const dataURLtoBlob = (dataURL) => {
    const parts = dataURL.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const encodedString = parts[1];

    try {
      const decodedString = atob(encodedString);
      const byteStringLength = decodedString.length;
      const arrayBuffer = new ArrayBuffer(byteStringLength);
      const uint8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteStringLength; i++) {
        uint8Array[i] = decodedString.charCodeAt(i);
      }

      return new Blob([arrayBuffer], { type: contentType });
    } catch (error) {
      console.error("Error decoding base64 string:", error);
      return null; // or handle the error in an appropriate way
    }
  };

  const handleDeleteImage = (id) => {
    const new_img_arr = [...selectedImageURL];
    const indexToDelete = new_img_arr.findIndex((item) => item.id == id);
    if (indexToDelete !== -1) {
      new_img_arr.splice(indexToDelete, 1);
    }
    setSelectedImageURL(new_img_arr);
    setGotoDetail(false);
  };

  const handlePlayMidi = (id) => {
    if (currentMidi == id) {
      setCurrentMidi(0);
    } else {
      setCurrentMidi(id);
    }
  };

  const handleScanButtonClick = async () => {
    setOpenScan(true);
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    videoRef.current.srcObject = stream;
    videoRef.current.play();
  };
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Layout>
      {loading && <PageLoader />}
      {showMessage && (
        <ToastMessage
          open={showMessage}
          onClose={handleMessageClose()}
          message={message}
        />
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Image selected" // Snackbar message
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      <Box>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography
            variant="h2"
            sx={{ fontWeight: "500", textTransform: "capitalize" }}
          >
            Tableau de bord
          </Typography>
        </Stack>

        <Box sx={{ mt: 2 }}>
          <Grid container spacing={6} mt={2} mb={7}>
            <Grid item xs={4} style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="primary"
                /*  onClick={e => {
                document.getElementById('photo_img').click();
              }} */
                onChange={(event) =>
                  handleFileInputChange(event.target.files[0])
                }
                component="label"
                role={undefined}
                startIcon={<CloudUpload />}
              >
                Télécharger une image/PDF
                <VisuallyHiddenInput type="file" />
              </Button>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<PhotoCamera />}
              >
                Analyse
              </Button>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "center" }}>
              <IconButton
                style={{ marginLeft: 4 }}
                color="secondary"
                onClick={() => handleZoomInClick()}
              >
                <ZoomIn />
              </IconButton>
              <IconButton
                style={{ marginLeft: 4 }}
                color="secondary"
                onClick={() => handleZoomOutClick()}
              >
                <ZoomOut />
              </IconButton>
              <IconButton
                style={{ marginLeft: 4 }}
                color="secondary"
                onClick={() => handleZoomOutMapClick()}
              >
                <ZoomOutMap />
              </IconButton>
              <IconButton
                style={{ marginLeft: 4 }}
                color={zoomFocus ? "secondary" : "primary"}
                onClick={() => handleZoomFocusClick()}
              >
                <CenterFocusStrong />
              </IconButton>
            </Grid>
          </Grid>
          <Paper
            elevation={3}
            sx={{
              py: 2,
              px: 2.5,
            }}
          >
            <Typography style={{ textAlign: "center", fontSize: "30px" }}>
              Image sélectionnée
            </Typography>
            {numPages > 1 && (
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <IconButton
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                  sx={{ color: currentPage === 1 ? "grey" : "primary.main" }}
                >
                  <SkipPreviousIcon />
                </IconButton>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "text.secondary",
                  }}
                >
                  Page {currentPage} of {numPages}
                </Typography>
                <IconButton
                  onClick={handleNextPage}
                  disabled={currentPage === numPages}
                  sx={{
                    color: currentPage === numPages ? "grey" : "primary.main",
                  }}
                >
                  <SkipNextIcon />
                </IconButton>
              </Stack>
            )}
            <Grid container margin={0} padding={0}>
              <Grid item xs={5} margin={0} padding={0}>
                <Box sx={{ marginLeft: "" }}>
                  <Grid container display={"block"}>
                    <Typography>images sélectionnées</Typography>
                    {selectedImageURL.map((item) => (
                      <Grid item xs={12} sm={6} md={4} key={item.id}>
                        <Paper
                          elevation={3}
                          sx={{ p: 2, position: "relative", width: "200%" }}
                        >
                          <IconButton
                            onClick={() => handleDeleteImage(item.id)} // Function to handle deletion
                            sx={{
                              position: "absolute",
                              right: "10px",
                              top: "10px",
                              color: "red",
                            }} // Position the button at the top-right corner of the paper
                            size="small"
                          >
                            <CloseIcon />
                          </IconButton>
                          <img
                            src={item.image}
                            alt="Cropped"
                            style={{ width: "100%", height: "auto" }}
                          />
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={7} margin={0} padding={0}>
                <div
                  style={{ width: "100%", height: "800px", overflow: "auto" }}
                  className={clsx(styles.imageContainer)}
                  id="image-container"
                >
                  <canvas
                    ref={canvasRef}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    style={{ height: "600px", width: "600px", left: 0, top: 0 }}
                    id="myCanvas"
                  />
                  <div
                    className={clsx(styles.enlargedImage)}
                    id="enlarged-image"
                  ></div>
                </div>
              </Grid>
            </Grid>

            <Box mt={7} pb={3}>
              <Grid container spacing={6}>
                <Grid item xs={6} style={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled
                    startIcon={<SendIcon />}
                    onClick={handleSubmitClick}
                    disabled={loading || selectedImageURL.length == 0}
                  >
                    Soumettre
                  </Button>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleGotoDetailClick}
                    disabled={!gotoDetail}
                  >
                    Aller au détail
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Layout>
  );
}
