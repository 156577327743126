import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles(theme => ({
  overlay: {
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 999999,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.5)', // semi-transparent white
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circularProgressRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const PageLoader = () => {
  const classes = useStyles();

  return (
    <Box className={classes.overlay}>
      <Box className={classes.circularProgressRoot}>
        <CircularProgress size={80} /> {/* Set the size to 80 */}
      </Box>
    </Box>
  );
};

export default PageLoader;
