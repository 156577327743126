import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  IconButton,
  TextField,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  Radio,
  FormControlLabel,
  RadioGroup,
  Grid,
} from "@mui/material";
import {
  PlayArrow,
  Stop,
  Undo,
  Print,
  CloudDownload,
  Edit,
} from "@mui/icons-material";
import { fetchError, fetchStart, fetchSuccess } from "../../redux/actions";
import { ApiURL, mediaURL } from "../../config";
import ToastMessage from "../../component/ToastMessage";
import { useDispatch } from "react-redux";
import CmtCard from "../../@coremat/CmtCard";
import CmtImage from "../../@coremat/CmtImage";
import PerfectScrollbar from "react-perfect-scrollbar";
import Layout from "../../layout/Layout";
import Draggable from "react-draggable";

import CmtCardContent from "../../@coremat/CmtCard/CmtCardContent";
import CmtCardHeader from "../../@coremat/CmtCard/CmtCardHeader";
// import makeStyles from '@material-ui/core/styles/makeStyles';
import { makeStyles, useTheme } from "@mui/styles";
import { useLocation } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    [theme.breakpoints.down("xs")]: {
      "& .Cmt-header-root": {
        flexDirection: "column",
      },
      "& .Cmt-action-default-menu": {
        marginLeft: 0,
        marginTop: 10,
      },
    },
  },
  cardContentRoot: {
    padding: 0,
  },
  scrollbarRoot: {
    height: 275,
  },
  imageContainer: {
    position: "relative",
  },
  editImage: {
    position: "absolute",
    bottom: -5,
    right: -5,
    zIndex: 2,
  },
}));

const DetailPage = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const location = useLocation();
  const [results, setResults] = useState(location?.state?.selectedImageURL);
  console.log(results, "results");
  // const results = location?.state?.selectedImageURL;
  console.log(results);

  const [currentMidi, setCurrentMidi] = useState(0);
  const [showImage, setShowImage] = useState(-1);
  const [selectedColor, setSelectedColor] = useState("red");
  // const [title, setTitle] = useState("");
  const theme = useTheme();
  const canvasRef = useRef(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isDrawing, setIsDrawing] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [zoomRate, setZoomRate] = useState(2);
  const [showCommentDialog, setShowCommentDialog] = useState(false);
  const [comments, setComments] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [comment, setComment] = useState("");

  const handleCommentOpen = (index) => {
    setShowCommentDialog(true);
    setEditIndex(index);
  };

  const handleCommentClose = () => {
    setShowCommentDialog(false);
  };

  const handleCommentSave = () => {
    const updatedComments = [...comments];
    updatedComments[editIndex] = comment;
    setComments(updatedComments);
    handleCommentClose();
  };
  const handleDeleteClick = (index) => {
    const updatedResults = [...results];
    updatedResults.splice(index, 1); // Remove the item at the specified index
    setResults(updatedResults); // Update the state with the new array
  };
  const handleArchiveClick = (index) => {
    const updatedResults = [...results];
    // Implement archive logic here, such as moving the item to an archive array
    updatedResults.splice(index, 1); // Remove the item from the main array
    setResults(updatedResults); // Update the state with the new array
  };
  useEffect(() => {
    if (showImage >= 0) {
      const handleImageLoad = () => {
        const canvas = canvasRef.current;
        if (canvas) {
          const ctx = canvas.getContext("2d");
          const img = new Image();
          img.onload = function () {
            let width = img.width * zoomRate;
            let height = img.height * zoomRate;

            // set the canvas dimensions to match the scaled image size
            canvas.width = width;
            canvas.height = height;

            // draw the image onto the canvas at the correct size
            ctx.drawImage(img, 0, 0, width, height);
          };
          img.src = results.find((item) => item.id == showImage).image;
        }
      };
      setTimeout(handleImageLoad, 0);
    }
  }, [showImage]);

  const handleMessageClose = () => () => {
    setShowMessage(false);
  };

  const handlePlayMidi = (id) => {
    if (currentMidi == id) {
      setCurrentMidi(0);
    } else {
      setCurrentMidi(id);
    }
  };

  const handleGoBackClick = () => {
    navigate(-1);
  };

  const handlePrintClick = () => {
    const table = document.getElementById("scan-table");
    if (!table) {
      console.error("Table not found.");
      return;
    }

    const tableContent = table.outerHTML;

    const htmlContent = `
      <!DOCTYPE html>
      <html>
      <head>
        <title>Table Content</title>
        <style>
          @page {
            size: A4;
            margin: 20mm 10mm;
          }
          body {
            margin: 0;
            padding: 0;
            font-family: Arial, sans-serif;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            border:none;
          }
          .textFieldsName{
            margin-left: 35%;
          }
          .textFieldsName input{
            border:none;
          }
          .textFieldsName1{
            margin-left: 20%;
            border:none;
          }
          .textFieldsName1 input{
            border:none;
          }
          th, td {
            border: none:
            padding: 8px;
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${tableContent}
      </body>
      </html>
    `;

    const blob = new Blob([htmlContent], { type: "text/html" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "table_content.html";
    a.click();

    // Cleanup
    URL.revokeObjectURL(url);
  };
  const [title1, setTitle1] = useState("");
  const [composer, setComposer] = useState("");
  const handleDownloadClick = () => {
    const table = document.getElementById("scan-table");
    if (!table) {
      console.error("Table not found.");
      return;
    }

    // Hide elements with class "toHideOnDownload"
    const elementsToHide = document.querySelectorAll(".toHideOnDownload");
    elementsToHide.forEach((element) => {
      element.style.display = "none";
    });

    const titleValue = title1; // Get the value of the title text field
    const composerValue = composer; // Get the value of the composer text field

    const tableContent = table.outerHTML;

    // Show hidden elements after capturing table content
    elementsToHide.forEach((element) => {
      element.style.display = ""; // Restore default display property
    });

    const htmlContent = `
      <!DOCTYPE html>
      <html>
      <head>
        <title>Table Content</title>
        <style>
          @page {       
                
            size: A4;
            margin: 20mm 10mm;
          }
          body {
            margin: 0;
            padding: 0;
            font-family: Arial, sans-serif;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            border:none;
          }
          .textFieldsName{
            // margin-left: 35%;
            width:'100%'
          }
          .textFieldsName input{
            border:none;
          }
          .textFieldsName1{
            // margin-left: 20%;
            border:none;
            width;'100%'
          }
          .textFieldsName1 input{
            border:none;
          }
          th, td {
            border: none;
            padding: 8px;
            text-align: left;
          }
        </style>
      </head>
      <body>
        ${tableContent}
      </body>
      </html>
    `;

    const blob = new Blob([htmlContent], { type: "text/html" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${titleValue}.html`;
    a.click();

    // Cleanup
    URL.revokeObjectURL(url);
  };

  const handleEditImageClick = (index) => {
    setShowImage(index);
  };

  const handleImageClose = () => {
    setShowImage(-1);
  };

  const handleSaveImageClick = () => {
    const canvas = canvasRef.current;

    const newImageWidth = canvas.width / zoomRate;
    const newImageHeight = canvas.height / zoomRate;

    const tempCanvas = document.createElement("canvas");
    const tempCtx = tempCanvas.getContext("2d");
    tempCanvas.width = newImageWidth;
    tempCanvas.height = newImageHeight;

    tempCtx.drawImage(canvas, 0, 0, newImageWidth, newImageHeight);

    const new_image = tempCanvas.toDataURL("image/png");

    results.find((item) => item.id === showImage).image = new_image;
    setShowImage(-1);
  };

  const handleColorChange = (event) => {
    setSelectedColor(event.target.value);
  };

  const handleImageMouseDown = (event) => {
    setIsDrawing(true);
    setStartX(event.nativeEvent.offsetX);
    setStartY(event.nativeEvent.offsetY);
  };

  const handleImageMouseMove = (event) => {
    if (!isDrawing) {
      return;
    }

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const currentX = event.nativeEvent.offsetX;
    const currentY = event.nativeEvent.offsetY;

    context.beginPath();
    context.moveTo(startX, startY);
    context.lineTo(currentX, currentY);
    context.strokeStyle = selectedColor;
    context.lineWidth = 2;
    context.stroke();

    setStartX(currentX);
    setStartY(currentY);
  };

  const handleImageMouseUp = () => {
    setIsDrawing(false);
  };

  const [dropZoneVisible, setDropZoneVisible] = useState(false);
  const [dropZoneIndex, setDropZoneIndex] = useState(null); // To know where to show the drop zone
  const handleDragOver = (e, index) => {
    e.preventDefault(); // Necessary for allowing drops
    console.log("Drag over at index:", index); // Debugging output
    setDropZoneVisible(true);
    setDropZoneIndex(index);
  };

  const handleDrop = (e, targetIndex) => {
    e.preventDefault();
    console.log("Dropped at index:", targetIndex); // Debugging output
    setDropZoneVisible(false); // Hide the drop zone
    const draggedId = e.dataTransfer.getData("text/plain");
    const draggedItem = results.find((x) => x.id === draggedId);

    if (!draggedItem) {
      console.error("Dragged item not found");
      return;
    }

    // Update the results array to reflect the image move
    const updatedResults = [...results];
    updatedResults.splice(dropZoneIndex, 1); // Remove the item from the original position
    updatedResults.splice(targetIndex, 0, draggedItem); // Insert the dragged item at the new position
    setResults(updatedResults); // Update the state with the new array
  };

  const handleDragStart = (e, id) => {
    e.dataTransfer.setData("text/plain", id);
    console.log("Dragging item id:", id); // Debugging output
  };

  return (
    <Layout>
      <Grid Container spacing={6}>
        <Grid item xs={12}>
          <CmtCard className={classes.cardRoot}>
            <CmtCardHeader
              className="pt4"
              title="RÉSULTAT"
              titleProps={{
                variant: "h4",
                component: "div",
                textAlign: "center",
                fontSize: "25px",
              }}
              sx={{ fontWeight: "bold", marginTop: "2%" }}
            >
              <Box clone mx={4}>
            
              </Box>
              <Box clone mx={4}>
                <Tooltip title="Download" aria-label="Download">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleDownloadClick}
                  >
                    <CloudDownload />
                  </Button>
                </Tooltip>
              </Box>
              <Box clone mx={4}>
                <Tooltip title="Return" aria-label="Return">
                  <Button color="primary" onClick={handleGoBackClick}>
                    <Undo />
                  </Button>
                </Tooltip>
              </Box>
            </CmtCardHeader>
            <CmtCardContent
              className={classes.cardContentRoot}
              id="scan-table"
              style={{ marginTop: "2%" }}
            >
              <TableCell
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <TextField
                  multiline
                  // minRows={3}
                  fullWidth
                  className="textFieldsName"
                  placeholder="Entrez le titre"
                  style={{ width: "100%", textAlign: "center", border: "none" }}
                  value={title1}
                  onChange={(e) => setTitle1(e.target.value)}
                  variant="standard"
                  inputProps={{
                    style: {
                      textAlign: "center",
                      fontSize: "30px",
                      border: "none",
                    },
                  }}
                  InputProps={{ disableUnderline: true }}
                />
              </TableCell>
              <TableCell
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  multiline
                  // minRows={3}
                  fullWidth
                  value={composer}
                  className="textFieldsName1"
                  placeholder="Entrez le compositeur"
                  style={{ width: "100%", textAlign: "center", border: "none" }}
                  variant="standard"
                  inputProps={{
                    style: {
                      textAlign: "center",
                      fontSize: "17px",
                      border: "none",
                    },
                  }}
                  onChange={(e) => setComposer(e.target.value)}
                  InputProps={{ disableUnderline: true }}
                />
              </TableCell>

              <div
                className="Cmt-table-resXponsive"
                style={{ marginLeft: "6%" }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold", fontSize: "18px" }}>
                        EXTRAITS
                      </TableCell>
                      <TableCell style={{ width: 50 }}></TableCell>
                      <TableCell style={{ width: 50 }}></TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", fontSize: "18px" }}
                        className="toHideOnDownload"
                      >
                        ECRIRE
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold", fontSize: "18px" }}>
                        COMMENTAIRE
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", fontSize: "18px" }}
                        className="toHideOnDownload"
                      >
                        ARCHIVE
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", fontSize: "18px" }}
                        className="toHideOnDownload"
                      >
                        SUPPRIMER
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {results.map((result, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <div
                            className={`jr-card-thumb image-container ${classes.imageContainer}`}
                            draggable
                            onDragStart={(e) => handleDragStart(e, result.id)}
                            onDrop={(e) => handleDrop(e, result.id)}
                            onDragOver={handleDragOver}
                          >
                            <Draggable>
                              <CmtImage
                                id={`image-${result.id}`}
                                src={result.image}
                                style={{ objectFit: "cover", cursor: "grab" }}
                              />
                            </Draggable>

                            {currentMidi && currentMidi === result.id ? (
                              <audio
                                src={`${mediaURL}${result.source}`}
                                controls
                                autoPlay={result.id === currentMidi}
                                toHideOnDownload
                                className="toHideOnDownload"
                              ></audio>
                            ) : null}
                           
                          </div>
                          {result.additionalImages &&
                            result.additionalImages.map((imgSrc, idx) => (
                              <CmtImage
                                key={idx}
                                src={imgSrc}
                                style={{ objectFit: "cover" }}
                              />
                            ))}
                          {dropZoneIndex === index && dropZoneVisible && (
                            <div
                              className="drop-zone toHideOnDownload"
                              style={{
                                display: "block",
                                height: "100px",
                                backgroundColor: "black",
                              }}
                            >
                              Drop here
                            </div>
                          )}
                        </TableCell>
                        <TableCell style={{ padding: "0px" }}>
                          {result.source ? (
                            <IconButton
                              style={{ marginLeft: 4 }}
                              className="toHideOnDownload"
                              color=""
                              onClick={() => handlePlayMidi(result.id)}
                            >
                              {result.id === currentMidi ? (
                                <Stop />
                              ) : (
                                <PlayArrow />
                              )}
                            </IconButton>
                          ) : null}
                        </TableCell>
                       
                        <TableCell style={{ width: 50 }}></TableCell>
                        <TableCell className="toHideOnDownload">
                          <IconButton
                            className={`${classes.editImage} toHideOnDownload`}
                            variant="contained"
                            color="secondary"
                            onClick={() => handleEditImageClick(result.id)}
                            id={`pencil-${index}`}
                          >
                            <Edit />
                          </IconButton>
                        </TableCell>

                        <TableCell>
                          <Button
                            className="toHideOnDownload"
                            variant="contained"
                            color="primary"
                            onClick={() => handleCommentOpen(index)}
                          >
                            Ajouter un commentaire
                          </Button>
                          <div
                            style={{ maxWidth: "30%", wordWrap: "break-word" }}
                          >
                            {comments[index]}
                          </div>
                        </TableCell>

                        <TableCell className="toHideOnDownload">
                          <IconButton onClick={() => handleArchiveClick(index)}>
                            <ArchiveIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell className="toHideOnDownload">
                          <IconButton onClick={() => handleDeleteClick(index)}>
                            <DeleteForeverIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Dialog open={showCommentDialog} onClose={handleCommentClose}>
                  {/* <DialogTitle>Add Comment</DialogTitle> */}
                  <DialogContent>
                    <TextField
                      label="COMMENTAIRE"
                      multiline
                      rows={4}
                      variant="outlined"
                      value={comments[editIndex]}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCommentClose}>Annuler </Button>
                    <Button onClick={handleCommentSave} color="primary">
                      Sauvegarder
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </CmtCardContent>
          </CmtCard>
        </Grid>
      </Grid>
      <Dialog
        fullScreen={fullScreen}
        open={showImage >= 0}
        onClose={handleImageClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Choisissez une tonalité de couleur et modifiez votre image."}
        </DialogTitle>
        <DialogContent>
          <Box>
            <RadioGroup
              aria-label="Color"
              name="color"
              value={selectedColor}
              onChange={handleColorChange}
            >
              <FormControlLabel
                value={"red"}
                control={<Radio style={{ color: "red" }} />}
                style={{ color: "red" }}
                label="Red"
              />
              <FormControlLabel
                value={"blue"}
                control={<Radio style={{ color: "blue" }} />}
                style={{ color: "blue" }}
                label="Blue"
              />
              <FormControlLabel
                value={"green"}
                control={<Radio style={{ color: "green" }} />}
                style={{ color: "green" }}
                label="Green"
              />
              <FormControlLabel
                value={"black"}
                control={<Radio style={{ color: "black" }} />}
                style={{ color: "black" }}
                label="Black"
              />
            </RadioGroup>
          </Box>
          <canvas
            ref={canvasRef}
            style={{ height: "500", width: "500", left: 0, top: 0 }}
            onMouseDown={handleImageMouseDown}
            onMouseMove={handleImageMouseMove}
            onMouseUp={handleImageMouseUp}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleImageClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveImageClick}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {showMessage && (
        <ToastMessage
          open={showMessage}
          onClose={handleMessageClose()}
          message={message}
        />
      )}
    </Layout>
  );
};

export default DetailPage;
