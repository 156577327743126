import React from 'react'
import {useNavigate , Routes, Route } from "react-router-dom"
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Navigate, Outlet } from 'react-router-dom';
import AboutPage from '../pages/AboutPAge'
import Signin from '../Auth/Signin'
import Signup from '../Auth/Signup'
import ForgotPassword from '../Auth/ForgotPassword'
import Index from '../Dashboard'
import DetailPage from '../pages/DetailPage';
import ContactMe from '../pages/ContactPage';
import ReportBug from '../pages/ReportBug';
import ProfilePage from '../Dashboard/Profile';



const RestrictedRoute = ({ component: Component, ...rest }) => {
  console.log("component",Component)
  console.log("rest",rest)
  const { authUser } = useSelector(({ auth }) => auth);
  let navigate = useNavigate();
  return (
    {/* <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          navigate('/signin', { state:{ from: props.location } })         
        )
      }
    /> */}
  );
};

export default function Router() {
  const { authUser } = useSelector(({ auth }) => auth);
  let navigate = useNavigate();
  const location = useLocation();
  console.log("location",location)
  console.log("authUser",authUser)

  /* if (location.pathname === '' || location.pathname === '/') {
    return navigate('/dashboard') ;

  } else */ if (authUser && (location.pathname === '/signin' || location.pathname === '/signup' || location.pathname === '/forgot-password')) {
    return navigate('/dashboard');
  }

  return (
 
      <Routes> 
        
        <Route element={<ProtectedRoute />}>
          {/* Protected routes go here */}
          <Route path="/" element={ <Index/> } />
          <Route path="/dashboard" element={ <Index/> } />
          <Route path="/detail-page" element={ <DetailPage/> } />
          <Route path="/profile" element={ <ProfilePage/> } />

          {/* You can add more protected routes inside this element */}
        </Route>
        <Route path='/report-bug' element={<ReportBug/>}/>
        <Route path="/contact-me" element={ <ContactMe/> } />
        <Route path="about" element={ <AboutPage/> } />
        <Route path="signin" element={ <Signin/> } />
        <Route path="signup" element={ <Signup/> } />
        <Route path="forgot-password" element={ <ForgotPassword/> } /> 
      </Routes>
 
  )
}


const ProtectedRoute = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  return authUser ? <Outlet /> :  <Navigate to="/signin" replace={true}  />;
};